import React from "react"
import { v4 as uuidv4 } from "uuid"
import Hero from "./Hero"
import loadable from "@loadable/component"

const Usp = loadable(() => import("./Usp"))
const Grid = loadable(() => import("./Grid"))
const Text = loadable(() => import("./Text"))
const ProductSlider = loadable(() => import("./ProductSlider"))
const Countdown = loadable(() => import("./Countdown"))
const Image = loadable(() => import("./Image"))
const Video = loadable(() => import("./Video"))
const IconGrid = loadable(() => import("./IconGrid"))
const Instruction = loadable(() => import("./Instruction"))
const Instagram = loadable(() => import("./Instagram"))

const WidgetLoader = ({ widgets }) => {
  if (!widgets || !widgets.length > 0) return false

  return widgets.map((widget) => {
    switch (widget._type) {
      case "textWidget":
        return <Text key={uuidv4()} data={widget} />
      case "hero":
        return <Hero key={uuidv4()} data={widget} />
      case "productSlider":
        return (
          <ProductSlider
            key={uuidv4()}
            title={widget.title}
            subTitle={widget.subTitle}
            products={widget.products}
            layout={widget.layout}
            columns="4"
          />
        )
      case "instruction":
        return <Instruction key={uuidv4()} data={widget} />
      case "grid":
        return <Grid key={uuidv4()} data={widget} />
      case "usp":
        return <Usp key={uuidv4()} data={widget} />
      case "countdown":
        return <Countdown key={uuidv4()} data={widget} />
      case "imageWidget":
        return <Image key={uuidv4()} data={widget} />
      case "video":
        return <Video key={uuidv4()} data={widget} />
      case "iconGrid":
        return <IconGrid key={uuidv4()} data={widget} />
      case "instagram":
        return <Instagram key={uuidv4()} data={widget} />
      default:
        return null
    }
  })
}

export default WidgetLoader
