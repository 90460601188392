import React, { useContext, useMemo } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { BrinkContext } from "../context/BrinkContext"
import { usePage } from "../context/PageContext"
import {
  containerLargeMaxWidth,
  MEDIA_MIN_MEDIUM,
  MEDIUM,
  MEDIA_MIN_LARGE
} from "../../constants"
import Button from "../../components/ui/Button"
import ReactPlayer from "react-player"

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
`

const BackgroundImage = styled(GatsbyImage)`
  filter: brightness(90%);
  transition: all 0.2s;
  filter: brightness(100%);
  width: 100%;
`

const MobileImage = styled(BackgroundImage)`
  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`

const DesktopImage = styled(BackgroundImage)`
  display: none;

  &:hover {
    filter: brightness(94%);
  }

  ${MEDIA_MIN_MEDIUM} {
    display: block;
    pointer-events: none;
  }
`

const Container = styled.div`
  max-width: ${containerLargeMaxWidth};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: ${(p) => p.mobilePos || "center"};
  padding-bottom: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -200rem;
    bottom: 0;
    left: -200rem;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 50%
    );
  }

  ${MEDIA_MIN_MEDIUM} {
    padding-bottom: ${(p) =>
      p.desktopVertPos === "flex-end" ? "6rem" : "3rem"};
    padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
    align-items: ${(p) => p.desktopVertPos || "flex-end"};
  }

  ${MEDIA_MIN_LARGE} {
    justify-content: ${(p) => p.desktopHorPos || "center"};
    &:after {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0) 50%
      );
    }
  }
`

const TextContainer = styled.div`
  margin: 10rem 3rem 3rem;
  max-width: 70rem;
  text-align: center;
  z-index: 1;

  ${MEDIA_MIN_MEDIUM} {
    margin: 0;
  }
`

const Text = styled.h1`
  display: block;
  color: ${(p) => p.theme.colors.white};
  padding: 0.4rem 0 0;
  font-size: 3rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  margin: 0.5rem 0;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 4rem;
    line-height: 4.2rem;
  }

  ${MEDIA_MIN_LARGE} {
    font-size: 6rem;
    line-height: 6.2rem;
    padding: 1.2rem 0 2rem;
  }
`

const Preamble = styled.p`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 0;
  font-size: 2rem;
  margin: 0 auto;
  padding: 1.5rem 1rem 0.8rem 1rem;
  letter-spacing: 0.1rem;
  text-transform: initial;
  text-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2rem;
    line-height: 2.8rem;
    padding: 1.5rem 1rem 1rem 1rem;
  }

  ${MEDIA_MIN_LARGE} {
    font-size: 2.4rem;
    line-height: 3rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
  }

  i {
    margin-left: 0.8rem;
    padding-top: 0.5rem;
  }
`

const CallToAction = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  a {
    margin: 0 auto;
  }
`

const CallToActionButton = styled(Button)`
  width: auto;
  height: 4rem;
  margin: 1.5rem auto 0;
  font-size: 1.3rem;
  position: relative;
  z-index: 10;

  ${MEDIA_MIN_MEDIUM} {
    height: 5rem;
    font-size: 1.5rem;
    margin: 1.5rem 1rem 0.5rem 0;
  }
`

const Video = styled.div`
  div {
    width: 100% !important;
  }
`

const MobileVideo = styled(Video)`
  ${MEDIA_MIN_MEDIUM} {
    display: none;
  }
`

const DesktopVideo = styled(Video)`
  display: none;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
    pointer-events: none;
  }
`

const Hero = ({ data }) => {
  const { languageCode } = useContext(BrinkContext)
  const { buildLanguage } = usePage()
  const {
    mobileImage,
    mobileContentPosition,
    desktopContentVerticalPosition,
    desktopContentHorizontalPosition,
    desktopImage,
    linkUrl,
    smallTitle,
    largeTitle,
    title,
    subTitle,
    buttonText,
    mobileVideoUrl,
    desktopVideoUrl
  } = data

  const images = useMemo(
    () =>
      withArtDirection(getImage(mobileImage.asset), [
        {
          media: `(min-width: ${MEDIUM}px)`,
          image: getImage(desktopImage.asset)
        }
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const localizedTitle = (title?.en && title[languageCode]) || title.en
  const localizedSmallTitle =
    (smallTitle?.en && smallTitle[languageCode]) || smallTitle?.en
  const localizedLargeTitle =
    (largeTitle?.en && largeTitle[languageCode]) || largeTitle?.en

  const playerOptions = {
    muted: true,
    playsinline: true,
    responsive: true,
    autoplay: true,
    loop: true,
    quailty: "4k"
  }

  const mobileVideo = useMemo(
    () => (
      <MobileVideo>
        <ReactPlayer
          url={mobileVideoUrl}
          width="100%"
          height="100%"
          config={{
            playerOptions: {
              ...playerOptions
            }
          }}
        />
      </MobileVideo>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const desktopVideo = useMemo(
    () => (
      <DesktopVideo>
        <ReactPlayer
          url={desktopVideoUrl}
          width="100%"
          height="100%"
          config={{
            playerOptions: {
              ...playerOptions,
              maxwidth: "3000px"
            }
          }}
        />
      </DesktopVideo>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Wrapper
      onClick={() => linkUrl && navigate(linkUrl[buildLanguage] || linkUrl.en)}
    >
      {mobileVideoUrl ? (
        mobileVideo
      ) : (
        <MobileImage image={images} alt={localizedTitle} loading="eager" />
      )}
      {desktopVideoUrl ? (
        desktopVideo
      ) : (
        <DesktopImage
          image={images}
          alt={`${localizedSmallTitle} ${localizedLargeTitle}`}
          loading="eager"
        />
      )}
      <Container
        desktopVertPos={desktopContentVerticalPosition}
        desktopHorPos={desktopContentHorizontalPosition}
        mobilePos={mobileContentPosition}
      >
        <TextContainer>
          {subTitle && (
            <Preamble>{subTitle[languageCode] || subTitle.en}</Preamble>
          )}
          {localizedTitle && <Text>{localizedTitle}</Text>}
          {buttonText && (
            <CallToAction>
              <CallToActionButton>
                {buttonText[languageCode] || buttonText.en}
              </CallToActionButton>
            </CallToAction>
          )}
        </TextContainer>
      </Container>
    </Wrapper>
  )
}

export default Hero
